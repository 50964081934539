import { inject, Injectable } from '@angular/core';
import { SettingsInterface } from '../settings/settings.interface';
import { getBrowserLang } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { SettingsService } from '../settings/settings.service';
import { GlobalStore } from '../../store/global.store';

@Injectable({ providedIn: 'root' })
export class LanguageHelperService {
  readonly store = inject(GlobalStore);
  settingsService = inject(SettingsService);

  async determineUserLanguage(): Promise<string> {
    // 1. Stored language is first priority
    // const settings: SettingsInterface | undefined = await lastValueFrom(this.settingsQuery.select().pipe(first(), untilDestroyed(this)));
    // if (settings?.language) {
    //   return settings.language;
    // }

    // 1.b. Stored in Cookie (mainly SSR)
    const cookieSettings: SettingsInterface | undefined = this.settingsService.getFromCookie();
    if (cookieSettings) {
      return cookieSettings.language;
    }

    // 2. User settings are second priority
    const user = this.store.user();
    if (user?.shtLanguageID) {
      return user.sLanguageIsoCode;
    }

    // 3. Browser lang or default locale is last priority
    const browserLang: string | undefined = getBrowserLang();
    return environment.availableLang.includes(browserLang || environment.defaultLocale)
      ? browserLang || environment.defaultLocale
      : environment.defaultLocale;
  }
}
